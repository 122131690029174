import React from 'react';
import '../css/main.css';

const Advertise = () => (
<div>
  <p className='font-semibold text-6xl text-slate-50 text-center mb-2'>💾 Upload.Land</p>
  <p className='font-semibold text-4xl text-slate-50 text-center mb-7'>Advertise</p>
  <div className='w-1/2 m-auto mb-5'>
    <p className='text-slate-50 mb-5'>If you want to advertise your business with us, please contact us at <a
        className='text-cyan-50 hover:text-sky-200' href="mailto:upload.land@pm.me">upload.land@pm.me</a> if interested!
    </p>
  </div>

  <hr style={{width: '30%', margin:'0 auto'}} />

  <div className='mt-4 text-center'>
    <a className='text-cyan-50 hover:text-sky-200' href="/terms">Terms of Use</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/faq">FAQ</a>
    <span className='text-slate-50 '> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/advertise">Advertise</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/abuse">REPORT ABUSE</a>
  </div>
</div>
);

export default Advertise;