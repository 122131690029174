import React from 'react';
import '../css/main.css';

const Abuse = () => (
<div>
  <p className='font-semibold text-6xl text-center text-slate-50 mb-2'>💾 Upload.Land</p>
  <p className='font-semibold text-4xl text-center text-slate-50 mb-7'>Abuse</p>

  <div className='w-1/2 m-auto mb-5'>
    <p className='text-slate-50'>If you believe that any of our users are violating our terms of use or your
      intellectual
      property rights, please file a report using the form below.</p>
    <p className='text-slate-50'>You must provide truthful information regarding your identity, the identity of your
      representative, and the file(s) involved in the report.</p>
    <p className='text-red-600'>* Please write your report only in English and respect our formatting correctly</p>
    <p className='text-slate-50'>To make a report please write an email to <a
        className='text-cyan-50 hover:text-sky-200' href="mailto:abuse.upload.land@pm.me">abuse.upload.land@pm.me </a>
      with the following
      format:</p>
    <p className='text-slate-50 m-0'>Reason of the report: (Spam, Harasement, DMCA, Pornography/Pedopornography, Child
      Abuse,
      Animal Abuse or Other)</p>
    <p className='text-slate-50 m-0'>Your Name:</p>
    <p className='text-slate-50 m-0'>The link to report:</p>
    <p className='text-slate-50 m-0'>The Report Description:</p>
  </div>

  <hr style={{width: '30%', margin:'0 auto'}} />

  <div className='mt-4 text-center'>
    <a className='text-cyan-50 hover:text-sky-200' href="/terms">Terms of Use</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/faq">FAQ</a>
    <span className='text-slate-50 '> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/advertise">Advertise</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/abuse">REPORT ABUSE</a>
  </div>
</div>
);

export default Abuse;