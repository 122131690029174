import React from 'react';
import '../css/main.css';
import App from '../components/App';
const top1 = ["https://i.imgur.com/gJSySeV.gif", "/advertise"]
const top2 = ["https://i.imgur.com/gJSySeV.gif", "/advertise"]

const down1 = ["https://i.imgur.com/adKIAO0.gif", "/advertise"]
const down2 = ["https://i.imgur.com/adKIAO0.gif", "/advertise"]
const down3 = ["https://i.imgur.com/adKIAO0.gif", "/advertise"]
const down4 = ["https://i.imgur.com/adKIAO0.gif", "/advertise"]
const down5 = ["https://i.imgur.com/adKIAO0.gif", "/advertise"]
const down6 = ["https://i.imgur.com/adKIAO0.gif", "/advertise"]

const Main = () => (
  <div>
    <p className='font-semibold text-6xl text-center text-slate-50 mb-7'>💾 Upload.Land <a className='text-xl text-center'>Beta (Soon)</a></p>
    <p className='font-semibold text-6xl text-center text-slate-50 mb-7'>We need a good React.JS Developer; Please contact at: upload.land@pm.me</p>
  </div>
)

export default Main;
