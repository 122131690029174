import React from 'react';
import '../css/main.css';

const Faq = () => (
<div className=''>
  <p className='font-semibold text-6xl text-center text-slate-50 mb-2'>💾 Upload.Land</p>
  <p className='font-semibold text-4xl text-center text-slate-50 mb-7'>Frequently Asked Questions</p>

  <div className='mb-5 w-1/2 m-auto'>
    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>How to share files ?</h4>
    <p className='text-slate-50'>Drop a file in the dedicated box, once the
      upload is complete we will redirect you to your unique URL so you can share your files</p>

    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>How long will my files be online?</h4>
    <p className='text-slate-50'><b>For long as possible</b> unless the file violates our <a
        className='text-cyan-50 hover:text-sky-200' href="/terms">Terms of Use</a>.
    </p>
    <p className='text-slate-50'>Please <a href="/abuse">report any findings</a> if you stumble upon something against
      our
      rules.</p>

    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>What are the limit of uploads?</h4>
    <p className='text-slate-50'>You are free to upload as long as you don't exceed the following restrictions:</p>
    <li className='text-slate-50'>Max <b>13.37 GB</b> per file.</li>
    <li className='text-slate-50'>Max <b>15 GB</b> per <b>hour</b> per IP.</li>

    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>Any restrictions on downloads?</h4>
    <p className='text-slate-50'><b>No.</b> We do not enforce any form of bandwith limitations on downloads.</p>
  </div>

  <hr style={{width: '30%', margin:'0 auto'}} />

  <div className='mt-4 text-center'>
    <a className='text-cyan-50 hover:text-sky-200' href="/terms">Terms of Use</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/faq">FAQ</a>
    <span className='text-slate-50 '> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/advertise">Advertise</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/abuse">REPORT ABUSE</a>
  </div>
</div>
);

export default Faq;