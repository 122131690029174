import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Main from './pages/Main';
import Terms from './pages/Terms';
import Advertise from './pages/Advertise';
import Abuse from './pages/Abuse';
import Faq from './pages/Faq';
import Download from './pages/Download';
import Uploaded from './pages/Uploaded';
import Upload from './pages/Upload';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<Main/>} />
    <Route path="/terms" element={<Terms/>} />
    <Route path="/advertise" element={<Advertise/>} />
    <Route path="/faq" element={<Faq/>} />
    <Route path="/abuse" element={<Abuse/>} />
    <Route path="/download" element={<Download/>} />
    <Route path="/download/:id" element={<Download/>} />
    <Route path="/download/:id/:file" element={<Download/>} />
    <Route path="/dl" element={<Download/>} />
    <Route path="/dl/:id" element={<Download/>} />
    <Route path="/dl/:id/:file" element={<Download/>} />
    <Route path="/uploaded/:id" element={<Uploaded/>} />
    <Route path="/uploaded/:id/:file" element={<Uploaded/>} />
    <Route path="/upload" element={<Upload/>} />
    <Route path="/upload/:a" element={<Upload/>} />
    <Route path="/upload/:a/:b" element={<Upload/>} />
  </Routes>
  </BrowserRouter>,
  rootElement
);