import React from 'react';
import '../css/main.css';

const Terms = () => (
<div>
  <p className='font-semibold text-6xl text-center text-slate-50 mb-2'>💾 Upload.Land</p>
  <p className='font-semibold text-4xl text-center text-slate-50 mb-7'>Terms Of Use</p>

  <div className='mb-5 w-1/2 m-auto'>
    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>General</h4>
    <p className='text-slate-50'>It is STRICTLY forbidden to spread viruses, Trojan, corrupted, illegal material and/or
      everything related to
      pedophilia and child porn via Upload.Land. Always check with your local laws before submitting content from
      this site.</p>

    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>Availability</h4>
    <p className='text-slate-50'>Upload.Land cannot guarantee full availability of the service, however, we do our best
      to
      keep the service as
      stable as possible. Upload.Land disclaims any responsibility for loss of revenue due to downtime and/or loss of
      data </p>

    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>Registration of downloads</h4>
    <p className='text-slate-50'>We are strong believers in privacy and do not record downloads.</p>

    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>Administrative Measures</h4>
    <p className='text-slate-50'>The administrators of Upload.Land have the right to delete and/or permanently block the
      content of files they
      deem inappropriate. </p>

    <h4 className='mt-3 text-slate-50 text-xl font-semibold'>Changes</h4>
    <p className='text-slate-50'>This User Agreement is subject to change at any time, so please review this agreement
      before you decide to use
      the Service. You are technically required to accept this Agreement by accessing or using the Service.</p>
  </div>

  <hr style={{width: '30%', margin:'0 auto'}} />

  <div className='mt-4 text-center'>
    <a className='text-cyan-50 hover:text-sky-200' href="/terms">Terms of Use</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/faq">FAQ</a>
    <span className='text-slate-50 '> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/advertise">Advertise</a>
    <span className='text-slate-50'> - </span>
    <a className='text-cyan-50 hover:text-sky-200' href="/abuse">REPORT ABUSE</a>
  </div>
</div>
);

export default Terms;