import {useState, useEffect} from "react";
import axios from "axios";
import '../css/App.css';

const chunkSize = 10 * 1024 * 1024;

function App() {
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);

  function handleDrop(e) {
    e.preventDefault();
    setFiles([...files, ...e.dataTransfer.files]);
  }

  function readAndUploadCurrentChunk() {
    const file = files[currentFileIndex];
    if (!file) {
      return;
    }
    
    if (file.size > 14355928187) {
      window.location.reload(false);
    } 
    const reader = new FileReader();
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadChunk(readerEvent) {
    const file = files[currentFileIndex];
    if (file.size > 14355928187) return;
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('currentChunkIndex', currentChunkIndex);
    params.set('totalChunks', Math.ceil(file.size / chunkSize));
    const headers = {'Content-Type': 'application/octet-stream'};
    const url = 'https://www.upload.land/upload?'+params.toString();
    axios.post(url, data, {headers})
      .then(response => {
        const file = files[currentFileIndex];
        const filesize = files[currentFileIndex].size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = currentChunkIndex === chunks;
        if (isLastChunk) {
          file.idUploaded = response.data.idUploaded;
          setLastUploadedFileIndex(currentFileIndex);
          setCurrentChunkIndex(null);
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 418) return window.location.reload(false);
      });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === files.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (files.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [files.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  if (files.length === 0) {
    return (
      <div>
        <div
          onDragOver={e => {setDropzoneActive(true); e.preventDefault();}}
          onDragLeave={e => {setDropzoneActive(false); e.preventDefault();}}
          onDrop={e => handleDrop(e)}
          className={"dropzone" + (dropzoneActive ? " active" : "")}>
          ➡️ Drop your files here ⬅️
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="files">
        {files.map((file,fileIndex) => {
          let progress = 0;
          let progressRnd = 0;
          if (file.idUploaded) {
            progress = 100;
            progressRnd = 100;
          } else {
            const uploading = fileIndex === currentFileIndex;
            const chunks = Math.ceil(file.size / chunkSize);
            if (uploading) {
              progress = currentChunkIndex / chunks * 100;
              progressRnd = Math.floor(progress);
            } else {
              progress = 0;
              progressRnd = 0
            }
          }
          if (file.idUploaded) {
            return (
              <head>
                <meta http-equiv="refresh" content={'0; url = https://upload.land/uploaded/'+file.idUploaded} />
              </head>
            );
          } else {
            if (currentChunkIndex === Math.ceil(file.size / chunkSize)-1) {
              return (
                <a className="file">
                  <div className="name">{file.name}</div>
                  <div className={"progress " + (progress === 100 ? 'done' : '')}
                      style={{width:progress+'%'}}>⌛ Processing...</div>
                </a>
              );
            } else {
              return (
                <a className="file">
                  <div className="name">{file.name}</div>
                  <div className={"progress " + (progress === 100 ? 'done' : '')}
                      style={{width:progress+'%'}}>{progressRnd}%</div>
                </a>
              );
            };
          }
        })}
      </div>
      </div>
    )
  }
}

export default App;